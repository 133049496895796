a{
  text-decoration: none;
  &:hover,
  &:active,
  &:focus,
  &:visited{
    text-decoration: none;
  }
}

.container{
  max-width: 1272px;
  margin: 0 auto;
}

.main{
  width: 100%;
  position: relative;
  background: #fff;
  box-shadow: 0px 36px 0px -25px rgba(0, 0, 0, 0.25);
  border-radius: 49px;
  padding: 56px 30px 56px 56px;
  margin-top: 100px;
  cursor: pointer;
  &__title{
    width: 312px;
    position: absolute;
    top: -46px;
    left: 50%;
    transform: translateX(-50%);
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    font-weight: 700;
    font-size: 37px;
    line-height: 43px;
    border-radius: 40px 40px 0px 0px;
    div{
      background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &__wrapper{
    display: flex;
    flex-wrap: wrap; 
  }
  &__block{
    width: calc(33.3% - 26px);
    position: relative;
    margin-top: 70px;
    margin-right: 26px;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3){
      margin-top: 0;
    }
    img{
      width: 100%;
    }
    &_number{
      width: 88px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -18px;
      left: 50%;
      transform: translateX(-50%);
      background: #FFFFFF;
      border-radius: 36px;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;
      color: #20371D;
    }
    &_open{
      width: 149px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      background: #FFFFFF;
      border-radius: 55px;
      div{
        text-transform: capitalize;
        background: linear-gradient(88.65deg, #2EAB0A 4.88%, #43E21B 99.85%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        cursor: pointer;
      }
      a{
        text-transform: capitalize;
        background: linear-gradient(88.65deg, #2EAB0A 4.88%, #43E21B 99.85%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        cursor: pointer;
      }
    }
  }
  &__warning {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 38px;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 31.8889px;
    line-height: 120%;
    background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}


.noBoxes{
  color: rgba(49,178,13,255);
  font-size: 36px;
  text-align: center;
  width: 100%;
}


@media screen and (max-width: 1320px){
  .container{
    max-width: 1050px;
  }
}
@media screen and (max-width: 1102px){
  .container{
    max-width: 991px;
  }
  .main{
    &__block_open div{
      font-size: 36px;
    }
    &__block_open{
      width: 158px;
      height: 55px;
    }

    &__warning {
      font-size: 26px;
    }
  }
  
}


@media screen and (max-width: 991px){
  .container{
    max-width: 768px;
    padding: 0 15px;
  }
}

@media screen and (max-width: 768px){
  .container{
    max-width: 100%;
  }
  .main {
    &__title{
      font-size: 28px;
      height: 55px;
      width: 236px;
      border-radius: 28px 28px 0px 0px;
      top: -40px;
    }
    &__block{
      width: calc(50% - 20px);
      margin-right: 10px;
    }
    &__block:nth-child(1), &__block:nth-child(2){
      margin-top: 0;
    }
    &__block:nth-child(3){
      margin-top: 70px;
    }
    &__warning {
      font-size: 19px;
    }
  }
  
}

@media screen and (max-width: 576px){
  .main {
    padding: 40px 17px;
    margin-top: 50px;

    &__wrapper{
      flex-direction: column;
    }
    &__block:nth-child(2){
      margin-top: 70px;
    }
    &__block{
      width: 100%;
    }

    &__warning {
      font-size: 16px;
    }
  }
  
}
