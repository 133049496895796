.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  
  video{
    height: 80%;
    border-radius: 100px;
  }

  &__bg {
    display: none;
    // justify-content: space-between;
    align-items: center;
    // opacity: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding-bottom: 70px;
    // background: radial-gradient(75.2% 34.73% at 50% 50%, #DBB8FF 41.82%, #C285FF 70.31%, #9C39FF 94.27%, #8B16FF 100%);
    background: url(../../image/modalShineBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    // transition: all 4s ease;
    &_active {
      display: flex;
      flex-direction: column;
      // opacity: 1;
    }
  }

  &__NFTCard {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    min-width: 470px;
    transition: all 300ms ease;
    &_active {
      transform: scale(1.05);
    }
  }

  &__continueBtn {
    visibility: hidden;
    transition: all 300ms ease;
    &_active {
      visibility: visible;
    }
  }

  &__header {
    margin-bottom: 40px;
    padding: 20px 20px 0;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    transition: all 300ms ease;
    width: 100%;
    &_active {
      visibility: visible;
    }
  }

  &__logo {
    width: 200px;

    img {
      width: 200px;
    }
  }

  &__account {
    img {
      width: 100px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    &__discordWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 45px;
      padding: 10px;
      background: #fff;
      box-shadow: 0px 21.32px 0px -11.1911px rgba(0, 0, 0, 0.25);
      border-radius: 55.4228px;
      transform: rotate(2.66deg);
    }

    &__discord {
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 23px;

      /* Green grad */

      background: linear-gradient(88.65deg, #2EAB0A 4.88%, #43E21B 99.85%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &__lightPaperWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3.95px;
      width: 152px;
      height: 35px;
      padding: 8px;
      background: #FFFFFF;
      box-shadow: 0px 15.78px 0px -8.28652px rgba(0, 0, 0, 0.25);
      border-radius: 41.038px;
      transform: rotate(-3.34deg);
      margin-top: 20px;
    }

    &__lightPaper {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      background: linear-gradient(89.73deg, #3300FF 0%, #00D1FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.card{
  transition:all .8s ease;
}


.card__disappears {
  transform: rotateY(-90deg);
}

@media screen and (max-width: 1320px){
  .card {
    &__NFTCard {
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 991px){
  .modal__buttons{
    display: none;
  }
  .modal__account_title{
    margin-right: 10px;
  }
  .modal__account{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
}