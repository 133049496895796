.loadingModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(22px);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;

  video{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__bear {
    position: absolute;
    top: 10px;
    img {
      width: 100%;
    }
  }

  &__title {
    position: absolute;
    top: calc(50% + 150px);

    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 640px){
  .loadingModal {
    &__bear {
      top: 50px;
    }
  }
}

@media screen and (max-width: 540px){
  .loadingModal {
    &__bear {
      top: 100px;
    }
    &__title {
      top: calc(50% + 130px);
    }
  }
}

@media screen and (max-width: 440px) {
  .loadingModal {
    &__title {
      top: calc(50% + 50px);
    }
  }
}

// @media screen and (max-width: 360px){
//   .loadingModal {
//     &__title {
//       top: calc(50% + 50px);
//     }
//   }
// }

// @media screen and (max-width: 321px){

// }

// @media screen and (max-width: 280px){

// }