.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 1609px;
  margin: 0 auto;
  padding: 49px 150px 27px;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__accountInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1325px){
  .header {
    padding: 49px 100px 27px;
  }
}

@media screen and (max-width: 991px){
  .header {
    padding: 49px 20px 27px;
    &__accountInfo {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0, 0);
    }
  }
}