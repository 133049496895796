.outlineBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 34.1063px 14.9215px;
  width: 168px;
  height: 47px;
  background: #fff;
  box-shadow: 0px 21.32px 0px -11.1911px rgba(0, 0, 0, 0.25);
  border-radius: 55.4228px;
  // transform: rotate(2.66deg);
  cursor: pointer;
}

.outlineBtn {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 27.5333px;
  line-height: 33px;
  background: linear-gradient(88.65deg, #2EAB0A 4.88%, #43E21B 99.85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
