.NFTCard {
  perspective:1500px;
  position:relative;
  width: 448px;
  height: 576px;
  &__side {
    position: absolute;
    top: 4px;
    left: 4px;
    transition:all .8s ease;
    backface-visibility: hidden;
    &__front {
      transform: rotateY(0deg);
      z-index: 3;
    }

    &__back {
      transform: rotateY(180deg);
    }
  }

  &__front {
    width: 448px;
    height: 576px;
  }

  &__back {
    width: 448px;
    height: 576px;
  }
}

.NFTSmallCard {
  perspective:1500px;
  position:relative;
  width: 311px;
  height: 400px;
  &__side {
    position: absolute;
    top: 4px;
    left: 4px;
    transition:all .8s ease;
    backface-visibility: hidden;

    &__front {
      transform: rotateY(0deg);
      z-index: 3;
    }

    &__back {
      transform: rotateY(180deg);
    }
  }

  &__front {
    width: 311px;
    height: 400px;
  }

  &__back {
    width: 311px;
    height: 400px;
  }
}

.NFTCard__active {
  .NFTCard__side__front {
    transform: rotateY(-180deg);
  }

  .NFTCard__side__back {
    transform: rotateY(0deg);
  }

  .NFTSmallCard__side__front {
    transform: rotateY(-180deg);
  }

  .NFTSmallCard__side__back {
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 1320px){
  .NFTCard {
    &__front {
      width: 311px;
      height: 400px;
    }
  
    &__back {
      width: 311px;
      height: 400px;
    }
  }
}
