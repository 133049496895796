.buttonBlock {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 653px;
  max-height: 284px;
  width: 100%;
  height: 100%;
}

.cardsGroup {
  width: 100%;
  height: 100%;
  
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.button{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 210px;
  transition: .2s ease-in-out;
  width: 579px;
  height: 125px;

  background: #fff;
  box-shadow: 0px 42.697px 0px -22.4122px rgba(0, 0, 0, 0.25);
  border-radius: 110.994px;
  cursor: pointer;
  &__inner{

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 63px;
    line-height: 76px;
    background: linear-gradient(88.11deg, #EE2915 -0.37%, #FF6D00 97.13%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &:hover{
    // transform: rotate(-2.65deg) scale(1.1);
    transform: scale(1.1);
    transition: .2s ease-in-out;
  }
}

@media screen and (max-width: 991px){
  .buttonBlock {
    width: 100%;
  }
  .button{
    padding: 15.1892px 30.3784px 13.2905px;
    width: 351.52px;
    height: 76.9px;
    box-shadow: 0px 18.9896px 0px -9.9679px rgba(0, 0, 0, 0.25);
    border-radius: 49.3648px;
    transform: rotate(2.66deg);
    &__inner{
      font-size: 35px;
      line-height: 48px;
    }

    &:hover{
      // transform: rotate(-2.65deg) scale(1.1);
      transform: rotate(2.66deg) scale(1.1);
      transition: .2s ease-in-out;
    }
  }
}
