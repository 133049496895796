.nftCard {
  box-sizing: border-box;
  width: 448px;
  height: 576px;
  border-radius: 34.2222px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: conic-gradient(from 225deg at 50% 50%, #BA02FA -46.87deg, #4200FF 39.37deg, #D02BEC 91.9deg, #FF00E5 133.12deg, #FF47AA 178.76deg, #5200FF 223.13deg, #CC36F3 256.87deg, #BA02FA 313.12deg, #4200FF 399.38deg);
  position: relative;
  
  &__background {
    width: 440px;
    height: 568px;
    border-radius: 34.2222px;
    background: radial-gradient(63.32% 63.32% at 50% 50%, #BC02EF 22.4%, #892CFF 100%);
    // background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &__backgroundImg {
    width: 440px;
    height: 568px;
    border-radius: 34.2222px;
    background: url(../../image/LavaBearBackground.png);
  }
  
  &__img {
    width: 380px;
    height: 477px;
    object-fit: fill;
  }

  &__titleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7.23px;

    position: absolute;
    width: 163.3px;
    height: 56.98px;
    left: calc(50% - 163.3px/2 - 1.69px);
    
    top: 472px;

    background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
    border: 6.22222px solid #fff;
    box-shadow: -0.777778px 18.6667px 0px -9.33333px rgba(0, 0, 0, 0.25);
    border-radius: 75.1703px;
    transform: rotate(-2.65deg);
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 31.8889px;
    line-height: 120%;
    color: #fff;
  }
}

.nftSmallCard {
  box-sizing: border-box;
  width: 311px;
  height: 400px;
  border-radius: 34.2222px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: conic-gradient(from 225deg at 50% 50%, #BA02FA -46.87deg, #4200FF 39.37deg, #D02BEC 91.9deg, #FF00E5 133.12deg, #FF47AA 178.76deg, #5200FF 223.13deg, #CC36F3 256.87deg, #BA02FA 313.12deg, #4200FF 399.38deg);
  position: relative;
  
  &__background {
    width: 301px;
    height: 390px;
    border-radius: 34.2222px;
    background: radial-gradient(63.32% 63.32% at 50% 50%, #BC02EF 22.4%, #892CFF 100%);
    // background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &__backgroundImg {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 301px;
    height: 390px;
    border-radius: 34.2222px;
    background: url(../../image/LavaBearBackground.png);
  }
  
  &__img {
    width: 265px;
    height: 333px;
    object-fit: fill;
  }

  &__titleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7.23px;

    position: absolute;
    width: 130px;
    height: 45px;
    left: calc(50% - 130px/2 - 1.69px);
    top: 331.65px;

    background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
    border: 6.22222px solid #fff;
    box-shadow: -0.777778px 18.6667px 0px -9.33333px rgba(0, 0, 0, 0.25);
    border-radius: 75.1703px;
    transform: rotate(-2.65deg);
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #fff;
  }
}
