.errorModal {
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(22px);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &__border {
    position: relative;
    padding: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-width: 760px;
    max-height: 573px;
    border-radius: 32px;
    background: conic-gradient(from 225deg at 50% 50%, #BA02FA -46.87deg, #4200FF 39.37deg, #D02BEC 91.9deg, #FF00E5 133.12deg, #FF47AA 178.76deg, #5200FF 223.13deg, #CC36F3 256.87deg, #BA02FA 313.12deg, #4200FF 399.38deg);
  }

  &__bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 100%;
    max-width: 760px;
    max-height: 573px;
    border-radius: 32px;
    background: linear-gradient(153.01deg, #DCF9FF 0.89%, #F9B1FF 88.53%);
  }

  &__badge {
    margin-bottom: 50px;
    width: 100%;
    height: 100%;
    max-width: 335px;
    max-height: 280px;
    background: url('../../image/errorNFTBadgeBG.svg');
    background-repeat: no-repeat;
    background-size: contain;
    &_bg {
      img {
        width: 280px;
        height: 250px;
        margin-left: 52px;
      }
    }
  }

  &__title {
    margin-bottom: 15px;
    width: 100%;
    max-width: 560px;
    // height: 87px;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 125%;

    text-align: center;

    background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5.33px;
    width: 182.21px;
    height: 76.92px;
    background: #fff;
    box-shadow: 0px 21.32px 0px -11.1911px rgba(0, 0, 0, 0.25);
    border-radius: 55.4228px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__buttonTitle {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 45.8596px;
    line-height: 54px;
    background: linear-gradient(88.65deg, #2EAB0A 4.88%, #43E21B 99.85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &__closeButton {
    position: absolute;
    width: 64px;
    height: 64px;
    right: -15px;
    top: -23px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #fff;
    border-radius: 80px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.1);
    }
    img {
      width: 31px;
      height: 31px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 530px){
  .errorModal {
    &__badge {
      max-width: 243px;
      max-height: 202px;

      img {
        width: 180px;
        height: 161px;
      }
    }

    &__title {
      margin-bottom: 36px;
      max-width: 253px;
      font-size: 24px;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5.33px;
      width: 109px;
      height: 46px;
      background: #fff;
      box-shadow: none;
      border-radius: 33px;
      cursor: pointer;
      transition: all 0.3s;
  
      &:hover {
        transform: scale(1.1);
      }
    }
  
    &__buttonTitle {
      font-size: 27px;
      line-height: 32px;
    }
  }
}
