.wrapper {
  max-width: 301px;
  // max-width: 377px;
}

.logo {
  width: 100%;
  // height: 197px;
}


@media screen and (max-width: 1320px){
  .wrapper{
    max-width: 280px;
  }
}

@media screen and (max-width: 768px){
  .wrapper{
    max-width: 250px;
  }
}

@media screen and (max-width: 576px){
  .wrapper{
    max-width: 143px;
  }
}
