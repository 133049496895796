.accountInfo {
  
  &__img {
    width: 110px;
    height: auto;
  }

  &__name {
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;

    color: #fff;
  }
}

// @media screen and (max-width: 1320px){
//   .accountInfo{
//     margin-left: -50px;
//   }
// }

@media screen and (max-width: 991px){
  .accountInfo {
    margin-left: 0;
    display: flex;
    flex-direction: row-reverse;

    &__name {
      display: flex;
      align-self: center;
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: 768px){
  .accountInfo {

    &__img {
      width: 80px;
      height: 80px;
      object-fit: contain;
      object-position: center center;
    }
    &__name {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

@media screen and (max-width: 576px){
  .accountInfo {

    &__img {
      width: 64px;
      height: 64px;
    }
    &__name {
      font-size: 14px;
      line-height: 18px;
    }
  }
}