a{
  text-decoration: none;
  &:hover,
  &:active,
  &:focus,
  &:visited{
    text-decoration: none;
  }
}

.headerButtons {
  display: flex;
  flex-direction: column;
  &__discordWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 34.1063px 14.9215px;
    width: 196px;
    height: 64px;
    background: #fff;
    box-shadow: 0px 21.32px 0px -11.1911px rgba(0, 0, 0, 0.25);
    border-radius: 55.4228px;
    transform: rotate(2.66deg);

    transition: all 0.3s ease;

    &:hover {
      transform: rotate(2.66deg) scale(1.03);
    }
  }

  &__discord {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;

    /* Green grad */

    background: linear-gradient(88.65deg, #2EAB0A 4.88%, #43E21B 99.85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__lightPaperWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 12.6271px 25.2542px 11.0487px;
    gap: 3.95px;
    width: 197px;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 15.78px 0px -8.28652px rgba(0, 0, 0, 0.25);
    border-radius: 41.038px;
    transform: rotate(-3.34deg);
    margin-top: 20px;

    transition: all 0.3s ease;

    &:hover {
      transform: rotate(-3.34deg) scale(1.03);
    }
  }

  &__lightPaper {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    background: linear-gradient(89.73deg, #3300FF 0%, #00D1FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media screen and (max-width: 1320px){
  .headerButtons{
    &__discordWrapper {
      width: 200px;
      height: 60px;
      // padding: 8px 20px 10.9215px;
    }

    &__discord {
      font-size: 30px;
      line-height: 40px;
    }

    &__lightPaperWrapper {
      width: 202px;
      height: 50px;
    }

    &__lightPaper {
      font-size: 23.9569px;
      line-height: 25px;
    }
  }
}

@media screen and (max-width: 768px){
  .headerButtons{
    &__discordWrapper {
      width: 150px;
      height: 45px;
      padding: 10px;
    }

    &__discord {
      font-size: 21px;
      line-height: 23px;
    }

    &__lightPaperWrapper {
      width: 152px;
      height: 35px;
      padding: 8px;
    }

    &__lightPaper {
      font-size: 18px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 576px){
  .headerButtons{
    &__discordWrapper {
      width: 100px;
      height: 35px;
      padding: 7px;
    }

    &__discord {
      font-size: 18px;
      line-height: 20px;
    }

    &__lightPaperWrapper {
      width: 102px;
      height: 25px;
      padding: 5px;
    }

    &__lightPaper {
      font-size: 14px;
      line-height: 15px;
    }
  }
}