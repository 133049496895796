.social {
  &__link {
    margin-right: 16px;
  }

  &__link:last-child {
    margin-right: 0;
  }

  &__img {
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}
