.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

} 

.App {
  width: 100%;
  text-align: center;
  &__header{
    &_wrapper{
      background: linear-gradient(to bottom right, #905CFF 0.18%, #D55CFF 100.59%);
      padding-bottom: 250px;
    }
  } 
  &__section{
    &_btn{
      margin-top: 72px;
      // height: calc(100vh - 778px);
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 182px;
    } 
  }
}

// @media screen and (max-width: 991px){
//   .App {
//     &__header{
//       &_wrapper{
//         padding-bottom: 250px;
//       }
//     }
//   }
// }
