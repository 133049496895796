.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &__bg {
    // display: none;
    // justify-content: space-between;
    display: flex;
    justify-content: center;
    align-items: center;
    // opacity: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding-bottom: 70px;
    background: radial-gradient(75.2% 34.73% at 50% 50%, #DBB8FF 41.82%, #C285FF 70.31%, #9C39FF 94.27%, #8B16FF 100%);
    // background: url(../../image/modalShineBg.png);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center center;
    // transition: all 4s ease;
    &_active {
      display: flex;
      flex-direction: column;
      // opacity: 1;
    }
  }

  &__link {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 27.6522px;
    line-height: 33px;
    background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}