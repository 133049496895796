.nftCard {
  box-sizing: border-box;
  width: 448px;
  height: 576px;
  border-radius: 34.2222px;
  background: conic-gradient(from 225deg at 50% 50%, #BA02FA -46.87deg, #4200FF 39.37deg, #D02BEC 91.9deg, #FF00E5 133.12deg, #FF47AA 178.76deg, #5200FF 223.13deg, #CC36F3 256.87deg, #BA02FA 313.12deg, #4200FF 399.38deg);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__background {
    width: 440px;
    height: 568px;
    border-radius: 34.2222px;
    background: radial-gradient(63.32% 63.32% at 50% 50%, #BC02EF 22.4%, #892CFF 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__top {
    margin-bottom: 2px;
    width: 436px;
    height: 400px;

    background: linear-gradient(153.01deg, #DCF9FF 0.89%, #F9B1FF 88.53%);
    border-radius: 24.1111px;

    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__imgBorder {
    width: 268px;
    height: 224px;
    margin-bottom: 24px;

    border: 8px solid #fff;
    border-top: none;

    background: #d9d9d9;
    border-radius: 0px 0px 1555.56px 1555.56px;
  }

  &__img {
    width: 256px;
    height: 216px;
    border-radius: 0px 0px 1555.56px 1555.56px;
    object-fit: none;
  }

  &__skills {
    width: 262px;
    height: 111px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__lucky {
    width: 100%;
    margin: 7px 0;
    display: flex;
    text-transform: uppercase;
    &_title {
      display: flex;
      align-items: center;
      margin-right: 19px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      background: linear-gradient(88.83deg, #FF5C00 0%, #FF00C7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &_value {

    }

    &_star {
      margin-right: 5px;
      width: 32px;
      height: 32px;
    }

    &_star:last-child {
      margin-right: 0;
    }
  }

  &__energy {
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    text-transform: uppercase;
    &_title {
      margin-right: 8px;
      display: flex;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 21px;
      background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &_value {
      display: flex;
      justify-content: flex-start;
      padding: 2px;
      // align-content: center;
      align-items: center;
      width: 125px;
      height: 28px;

      background: #fff;
      border-radius: 7px;
    }

    &_box {
      width: 28px;
      height: 22px;
      margin-right: 2px;
    }

    &_box:last-child {
      margin-right: 0;
    }
  }

  &__level {
    width: 100%;
    display: flex;

    &_title {
      margin-right: 22px;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 21px;
      background: linear-gradient(89.73deg, #3300FF 0%, #00D1FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &_value {
      display: flex;
      justify-content: center;
      align-items: center;

      &__bar {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 175px;
        height: 15px;

        background: linear-gradient(88.83deg, #253AFF 5.73%, #02C8FF 94.27%);
        border-radius: 12.4444px;
      }

      &__circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        opacity: 50%;
        background: #22C2FF;
      }

      &__select {
        box-sizing: border-box;
        width: 20px;
        height: 20px;

        background: #fff;
        border: 9.33333px solid #0500FF;
      }
    }
  }
  
  &__bottom {
    width: 432px;
    height: 158px;
    display: flex;
    align-items: center;

    border-radius: 0 0 34.2222px 34.2222px;

    background: linear-gradient(265.78deg, #892CFF 5.19%, #BE00EE 52.58%, #8600EF 105.19%), linear-gradient(0deg, #B82FFF, #B82FFF);

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 125%;
    text-align: center;

    color: #fff;
  }

  &__titleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7.23px;

    position: absolute;
    width: 130px;
    height: 44px;
    left: calc(50% - 130px/2 - 73px);
    top: 377px;

    background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
    border: 6.22222px solid #fff;
    box-shadow: -0.777778px 18.6667px 0px -9.33333px rgba(0, 0, 0, 0.25);
    border-radius: 75.1703px;
    transform: rotate(-2.65deg);
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #fff;
  }

  &__subtitleWrapper {
    display: flex;
    justify-content: center;
    // align-items: center;
    padding: 5px 10px 5px 7px;
    gap: 8px;

    position: absolute;
    // width: 126px;
    height: 39px;
    left: 242px;
    top: 380px;
    z-index: 1;

    background: #fff;
    border-radius: 28px;
    transform: rotate(4.99deg);
  }

  &__subtitleBall {
    width: 28px;
    height: 28px;
    background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
    border-radius: 33.25px;
    transform: rotate(4.99deg);
  }

  &__subtitle {
    margin-top: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 100%;
    color: #000;
  }

  &__downloadButton {
    padding: 0;
    border: none;
    border-radius: 50%;
    width: 51px;
    height: 51px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &__downloadButtonImg {
    width: 51px;
    height: 51px;
  }
}

.nftSmallCard {
  box-sizing: border-box;
  width: 311px;
  height: 400px;
  border-radius: 34.2222px;
  background: conic-gradient(from 225deg at 50% 50%, #BA02FA -46.87deg, #4200FF 39.37deg, #D02BEC 91.9deg, #FF00E5 133.12deg, #FF47AA 178.76deg, #5200FF 223.13deg, #CC36F3 256.87deg, #BA02FA 313.12deg, #4200FF 399.38deg);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__background {
    width: 301px;
    height: 390px;
    border-radius: 34.2222px;
    background: radial-gradient(63.32% 63.32% at 50% 50%, #BC02EF 22.4%, #892CFF 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__top {
    margin-bottom: 2px;
    width: 301px;
    height: 279px;

    background: linear-gradient(153.01deg, #DCF9FF 0.89%, #F9B1FF 88.53%);
    border-radius: 24.1111px 24.1111px 0 0;

    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__imgBorder {
    width: 186px;
    height: 155px;
    margin-bottom: 10px;

    border: 8px solid #fff;
    border-top: none;

    background: #d9d9d9;
    border-radius: 0px 0px 1555.56px 1555.56px;
  }

  &__img {
    width: 170px;
    height: 140px;
    border-radius: 0px 0px 1555.56px 1555.56px;
    object-fit: none;
  }

  &__skills {
    width: 183px;
    height: 78px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__lucky {
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    text-transform: uppercase;
    &_title {
      display: flex;
      align-items: center;
      margin-right: 19px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      background: linear-gradient(88.83deg, #FF5C00 0%, #FF00C7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &_value {

    }

    &_star {
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }

    &_star:last-child {
      margin-right: 0;
    }
  }

  &__energy {
    width: 100%;
    margin-bottom: 14px;
    display: flex;
    text-transform: uppercase;
    &_title {
      margin-right: 10px;
      display: flex;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &_value {
      display: flex;
      justify-content: flex-start;
      padding: 2px;
      // align-content: center;
      align-items: center;
      width: 87px;
      height: 19px;

      background: #fff;
      border-radius: 7px;
    }

    &_box {
      width: 19px;
      height: 16px;
      margin-right: 2px;
    }

    &_box:last-child {
      margin-right: 0;
    }
  }

  &__level {
    width: 100%;
    display: flex;

    &_title {
      margin-right: 16px;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      background: linear-gradient(89.73deg, #3300FF 0%, #00D1FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &_value {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &__bar {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 122px;
        height: 10px;

        background: linear-gradient(88.83deg, #253AFF 5.73%, #02C8FF 94.27%);
        border-radius: 12.4444px;
      }

      &__circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        opacity: 50%;
        background: #22C2FF;
      }

      &__select {
        box-sizing: border-box;
        width: 14px;
        height: 14px;

        background: #fff;
        border: 5px solid #0500FF;
      }
    }
  }
  
  &__bottom {
    width: 301px;
    height: 108px;
    display: flex;
    align-items: center;
    border-radius: 0 0 24.1111px 24.1111px;

    background: linear-gradient(265.78deg, #892CFF 5.19%, #BE00EE 52.58%, #8600EF 105.19%), linear-gradient(0deg, #B82FFF, #B82FFF);

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    text-align: center;

    color: #fff;
  }

  &__titleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7.23px;

    position: absolute;
    width: 91px;
    height: 31px;
    left: calc(50% - 90.87px/2 - 47.86px);
    top: 268.63px;

    background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
    border: 3.46235px solid #fff;
    box-shadow: -0.432794px 10.3871px 0px -5.19353px rgba(0, 0, 0, 0.25);
    border-radius: 41.8285px;
    transform: rotate(-2.65deg);
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #fff;
  }

  &__subtitleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px 5px 7px;
    gap: 10.5px;

    position: absolute;
    // width: 88px;
    height: 27px;
    left: 168.95px;
    top: 268.33px;

    background: #fff;
    border-radius: 28px;
    transform: rotate(4.99deg);
  }

  &__subtitleBall {
    width: 19px;
    height: 19px;
    background: linear-gradient(89.6deg, #EB00FF 0%, #5200FF 100%);
    border-radius: 33.25px;
    transform: rotate(4.99deg);
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    color: #000;
  }

  &__downloadButton {
    padding: 0;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &__downloadButtonImg {
    width: 35px;
    height: 35px;
  }
}
