.wrapper{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: white;
  gap: 25px;
}

.border{
  max-width: 1017px;
  padding: 2px;
  border-radius: 34px;
  border: 2px solid red;
  padding: 45px;
  position: relative;
  margin: 93px auto 0 auto;
  display: flex;
  justify-content: center;
}

.section__title{
  padding: 10px;
  background: white;
  position: absolute;
  top: -49px;
  left: 50%;
  transform: translateX(-50%);
  div{
    background: linear-gradient(88.83deg, #FF5C00 0%, #FF00C7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: 45.8596px;
    line-height: 54px;
  } 
}

.card {
  width: 448px;
  height: 576px;
}

.smallCard {
  width: 311px;
  height: 400px;
}

.warning {
  width: 100%;
  text-align: center;

  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 125%;

  background: linear-gradient(88.83deg, #FF5C00 0%, #FF00C7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@media screen and (max-width: 1320px){
  .card{
    width: 311px;
    height: 400px;
    &__side{
      &__bear{
        max-width: 265px;
        width: 100%;
      }
      &__front{
        &_btn{
          width: 100px;
        }
      }
      &__back{
        &__info{
          &_logo{
            width: 186px;
            height: 155px;

            img {
              width: 170px;
              height: 140px;
            }
          }
          &_stats{
            width: 183px;
            height: 78px;
            margin-top: 15px;
            margin-bottom: 15px;
          }
        }
        &_btns{
          img{
            width: 100px;
          }
        }
        &__text{
          font-size: 12px;
        }
      }  
    }
    &__save{
      width: 35px;
      height: 35px;
    }
  }

  .wrapper{
    display: flex;
    // justify-content: center;
    flex-wrap: wrap;
    background: white;
  }

  .border{
    max-width: 738px;
    padding: 20px 35px;

  }

  // .border{
  //   max-width: 1050px;
  //   padding: 35px;
  // }

  .section__title{
    top: -31px;
    div{
      font-size: 28px;
      line-height: 33px;
    } 
  }
}

@media screen and (max-width: 1102px){
  .card{
    &:nth-child(even){
      margin-left: 10px;
    }
    &:nth-child(odd){
      margin-right: 10px;
    }
  }

  // .border{
  //   max-width: 991px;
  //   padding: 25px;
  // }
  
}

@media screen and (max-width: 991px){
  .card{
    &:nth-child(even){
      margin-left: 5px;
    }
    &:nth-child(odd){
      margin-right: 5px;
    }
  }

  // .border{
  //   max-width: 738px;
  //   // padding: 10px;
  // }
}

@media screen and (max-width: 720px) {
  .wrapper {
    justify-content: center;
  }
}

@media screen and (max-width: 685px){
  .card{
    margin-bottom: 20px;
    &:nth-child(even){
      margin-left: 0;
    }
    &:nth-child(odd){
      margin-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .border{
    margin-top: 40px;
    max-width: 626px;
    padding: 20px;
  }
}

@media screen and (max-width: 321px){
  .section__title {
    div{
      font-size: 24px;
      line-height: 125%;
    } 
  }
}

@media screen and (max-width: 280px){
  .section__title {
    div{
      font-size: 22px;
      line-height: 125%;
    } 
  }
}
