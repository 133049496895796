a{
  text-decoration: none;
  &:hover,
  &:active,
  &:focus,
  &:visited{
    text-decoration: none;
    color: #000000;
  }
}

.main {
  // width: 1920px;
  height: 178px;
  margin: 50px 0;
  &__title {
    margin-bottom: 37px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 125%;
    text-align: center;

    color: #000000;
  }

  &__social {
    margin-bottom: 23px;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;

    text-align: center;
    text-decoration-line: underline;

    color: #000000;
  }
}
